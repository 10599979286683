<template>
  <div>
       <Form label-position="left" :label-width="150">
           <FormItem>
                <span slot="label" class="validate">部门名称:</span>
                <Input placeholder="请输入部门名称" v-model.trim="formData.orgName" :maxlength="10"></Input>
            </FormItem>
             <FormItem >
                <span slot="label" >上级部门:</span>
                <Cascader :data="treeData" v-model="formData.treeParentId" change-on-select @on-change="changeCascader"></Cascader>
                <!-- <Tree :data="treeData"></Tree> -->
            </FormItem>
             <FormItem>
                <span slot="label" >排序:</span>
                <InputNumber :min="0" v-model="formData.seq"></InputNumber>
            </FormItem>
       </Form>
  </div>
</template>

<script>

  export default {
    props:{
        formData:{
            type:Object,
            default:()=>{
                return {}
            }
        },
        orgId:{
          type:String,
          default:()=>{
            return ''
          }
        },
        isShow:{
          type:Number,
          default:()=>{
            return 0
          }
        }

    },
    //import引入的组件需要注入到对象中才能使用
    components: {},
    data() {
      //这里存放数据
      return {
        treeData:[]
      }
    },
    //方法集合
    methods: {
      changeCascader(val){
          this.$emit('changeCascader',val)
      },
      getTree(){
        if(this.orgId != ''){
          this.$get('/gx/pc/dept/selectDeptTree',{
            orgId:this.orgId
          }).then(res=>{
            if(res && res.code == 200 && res.dataList.length != 0){
              this.treeData = this.forMatterTree(res.dataList)
            }else{
              this.treeData = []
            }
          })
        }
        
      },
      // 格式化树结构，给树结构添加上value值和label值
      forMatterTree(data) {
        if(data && data != null && data.length != 0){
          for (var i = 0; i < data.length; i++) {
            data[i].label = data[i].title;
            data[i].value = data[i].id;
            if (data[i].children && data[i].children != 'null' && data[i].children.length > 0 ) {
              this.forMatterTree(data[i].children);
            }
          }
        }
        
        return data;
      },
    },
    //生命周期 - 创建完成（可以访问当前this实例）
    watch:{
      isShow:{
        handler(val,newVal){
          if(val){
            this.getTree()
          }
        }
      },
      // formData:{
      //   handler(val,newVal){
      //     if(val){
      //       console.log(val);
      //     }
      //   }
      // }
    }
  }
</script>
<style lang='less' scoped>
  //编写css
   /deep/.validate {
    &::before {
        content: "*";
        display: inline-block;
        margin-right: 4px;
        line-height: 1;
        font-family: SimSun;
        font-size: 14px;
        color: #ed4014;
    }
  }
</style>